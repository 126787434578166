export default {
  methods: {
    isValidName(name) {
        const specificationKeys = Object.values(this.product.specification)
          .map((spec) => Object.keys(spec))
          .flat();
        if (
          ['OUT', 'ASK', 'IN', 'Cover', ...specificationKeys]
            .map((k) => k.toUpperCase())
            .includes(name.trim().toUpperCase())
        ) {
          return false;
        }
        return true;
    },
    isRuleNameUnique(name) {
        for (let i = 0; i < this.product.rules.length; i += 1) {
            if (this.product.rules[i].name.toLowerCase() === name.trim().toLowerCase()) {
              return false;
            }
        }
        return true;
    }
  }
};
