<template>
  <div>
    <draggable :id="`${folder || 'root'}`" class="rules-items rules" :options="{
      disabled: isReadOnlyMode || product.locked || folder === 'default',
      group: 'rules-items',
      handle: '.rule-handle'
    }" :move="checkMove" @end="saveRulesOrder">
      <slot name="folder"></slot>
    </draggable>
    <b-collapse :id="`${folder.replaceAll(' ', '_')}-items`" v-model="visible">
      <draggable :id="folder || 'root'" v-model="rulesCopy" class="rules-items rules" :options="{
      disabled: isReadOnlyMode || product.locked || folder === 'default',
      group: 'rules-items',
      handle: '.rule-handle'
    }" :move="checkMove" @end="saveRulesOrder">
        <li v-for="rule in rulesCopy" :key="rule.id" class="rule rule-handle" @click="SET_RULE_ID(rule.id)">
          <router-link v-slot="{ href, navigate, isActive }" :to="{
      name: 'product-rules-edit',
      params: { productId: getLatestProductId, ruleId: rule.id },
      query: $router.currentRoute.query
    }" tag="div" class="sidebar-link-wrapper">
            <div :class="{ active: isActive }">
              <a :href="href" class="px-4 py-2 d-flex align-items-start" :class="{ 'text-dark': !isActive }" @click="
      $event.preventDefault();
    navigate(href);
    ">
                <b-icon v-if="folder !== 'default'" icon="grip-vertical" class="grip mt-1" :class="{
      'ml-4': folder && folder !== 'default' && folder !== 'root' && !(draggedRule == rule.id && rootDrag)
    }" />
                <b-icon class="mr-1 mt-1" :icon="isActive ? 'diagram-3-fill' : 'diagram-3'" rotate="-90" />
                <i v-if="hasCycle(rule)" v-b-tooltip.hover :title="cycleTipContent"
                  class="fas el-icon-warning mt-1"></i>
                <i v-if="!hasCycle(rule) && !isMatched(rule)" v-b-tooltip.hover :title="toolTipContent"
                  class="fas el-icon-warning mt-1"></i>
                <i v-if="!hasCycle(rule) && isMatched(rule) && isEmpty(rule)" v-b-tooltip.hover :title="emptyTipContent"
                  class="fas el-icon-warning mt-1"></i>
                <div v-if="folder && folder !== 'default' && folder !== 'root'">
                  {{ rule.name.split('/')[1] }}
                </div>
                <span v-else>{{ rule.name }}</span>
              </a>

              <div v-if="!isReadOnlyMode && !product.locked" class="actions d-flex ml-auto mr-3">
                <button v-if="rule.id !== defaultRule.id" title="Duplicate Rule" class="ui_button_duplicate"
                  @click="duplicateRule(rule)">
                  <i class="far fa-copy"></i>
                </button>
                <button v-if="rule.id !== defaultRule.id" title="Edit Rule" class="ui_button_edit"
                  @click="editRuleName(rule)">
                  <i class="far fa-edit"></i>
                </button>
                <button v-if="rule.id !== defaultRule.id" title="Delete Rule" class="ui_button_delete"
                  @click="removeRule(rule)">
                  <i class="far fa-trash-alt"></i>
                </button>
                <button title @click="toggleLock(rule)">
                  <i :class="rule.is_locked ? 'fas fa-lock' : 'fas fa-lock-open'
      " class="ui_button_lock"></i>
                </button>
              </div>
              <div v-if="hasWarnings(rule)" class="warnings-count">
                {{ numberOfWarnings(rule) }}
              </div>
            </div>
          </router-link>
        </li>
      </draggable>
    </b-collapse>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { MessageBox, Message } from 'element-ui';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import * as api from '../api';
import { getDefaultGraph } from '../util';
import RulesNameMixin from './RulesNameMixin';

export default {
  name: 'RulesSidebarItems',
  components: {
    draggable
  },
  mixins: [RulesNameMixin],
  props: {
    unusedRules: { type: Array, required: true },
    cycleRules: { type: Array, required: true },
    folder: { type: String, default: 'root' }
  },
  data: () => ({
    toolTipContent: 'This rule is not used.',
    emptyTipContent: 'This rule is empty.',
    cycleTipContent: 'There is a cycle involving this rule.',
    warningsSwitch: false,
    rulesCopy: [],
    draggedRule: undefined,
    moveError: undefined,
    rootDrag: false,
    visible: false
  }),
  computed: {
    ...mapState('product', [
      'sidebarIsVisible',
      'product',
      'warnings',
      'requestWarnings'
    ]),
    ...mapState('auth', ['tenant']),
    ...mapGetters('product', ['isReadOnly']),
    ...mapGetters('auth', ['isGuest']),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    defaultRule() {
      let rule = this.product.rules.find(
        (r) => r.id === this.product.main_rule_id
      );
      if (!rule) {
        rule = this.product.rules.find(
          (r) => r.name === 'Cover' || r.name === 'Questions'
        );
      }
      return rule;
    },
    getLatestProductId() {
      return this.product.version.latest;
    },
    ruleFolders() {
      return new Set(
        this.product.rules
          .filter((rule) => rule.name.includes('/'))
          .map((r) => r.name.split('/')[0])
      );
    }
  },
  watch: {
    product: {
      handler() {
        this.rulesCopy = this.getRulesSorted(
          cloneDeep(
            this.product.rules.filter((rule) => {
              if (this.folder === 'root') {
                return (
                  !rule.name.includes('/') &&
                  !(rule.name === 'Cover' || rule.name === 'Questions')
                );
              }
              if (this.folder === 'default') {
                return rule.name === 'Cover' || rule.name === 'Questions';
              }
              return rule.name.startsWith(`${this.folder}/`);
            })
          )
        );
      },
      immediate: true
    }
  },
  created() {
    this.warningsSwitch = this.requestWarnings;
    this.rulesCopy = this.getRulesSorted(
      cloneDeep(
        this.product.rules.filter((rule) => {
          if (this.folder === 'root') {
            return (
              !rule.name.includes('/') &&
              !(rule.name === 'Cover' || rule.name === 'Questions')
            );
          }
          if (this.folder === 'default') {
            return rule.name === 'Cover' || rule.name === 'Questions';
          }
          return rule.name.startsWith(`${this.folder}/`);
        })
      )
    );

    if (this.folder === 'default' || this.folder === 'root') {
      this.visible = true;
    }
    this.$root.$on('bv::toggle::collapse::true', (id) => {
      if (id === this.folder) {
        this.visible = true;
      }
    })
  },
  methods: {
    ...mapMutations('product', ['SET_RULE_ID']),
    ...mapActions('product', [
      'fetchProduct',
      'toggleRequestWarnings',
      'refreshProduct',
      'renameRule',
      'updateRule',
      'deleteRule'
    ]),
    hasWarnings(rule) {
      return (
        this.warnings &&
        Object.keys(this.warnings).length &&
        this.warnings[rule.name] &&
        this.warnings[rule.name].filter((w) => w.id !== 0).length
      );
    },
    numberOfWarnings(rule) {
      return this.warnings[rule.name].filter((w) => w.id !== 0).length;
    },
    isNotDefaultRule(id) {
      const { defaultRule } = this;
      return defaultRule.id !== id;
    },
    getRulesSorted(rules) {
      const sortedRules = cloneDeep(rules);
      return sortedRules
        .sort((r1, r2) => {
          if (r1.position > r2.position) return 1;
          if (r2.position > r1.position) return -1;
          return 0;
        })
        .map((r, index) => {
          // standarize index
          // eslint-disable-next-line no-param-reassign
          r.position = index;
          return r;
        });
    },
    async toggleLock(rule) {
      try {
        if (rule.is_locked)
          await MessageBox.confirm(
            `Are you sure you wish to unlock “${rule.name}”`
          );
        // eslint-disable-next-line no-param-reassign
        rule.is_locked = !rule.is_locked;
        // eslint-disable-next-line
        await api.updateRule(rule.id, rule);
        this.$emit('update');
        Message.info(
          `The rule has been ${rule.is_locked ? 'locked' : 'unlocked'}`
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async duplicateRule(rule) {
      try {
        const { value } = await MessageBox.prompt(
          'Name of new rule',
          'Duplicate Rule',
          { inputValue: `${rule.name} (copy)` }
        );
        this.$emit("add-rule", { value, graph: rule.graph });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async editRuleName(rule) {
      if (rule.is_locked) {
        Message.error('The rule is locked');
        return;
      }
      let newName = null;
      try {
        newName =
          (
            await MessageBox.prompt(
              this.$t('rule-editor.rules.create_rule_tip'),
              this.$t('rule-editor.rules.create_rule'),
              {
                inputValue: rule.name
              }
            )
          ).value;
      } catch {
        return;
      }

      if (newName !== rule.name) {
        this.saveRuleName(rule, newName)
      }
    },
    async saveRuleName(rule, newName) {
      if (rule.is_locked) {
        Message.error('The rule is locked');
        return;
      }
      if (!this.isValidName(newName)) {
        Message.error(
          this.$t('rule-editor.rules.reserved_word', {
            name: newName
          })
        );
        return;
      }
      if (!this.isRuleNameUnique(newName)) {
        Message.error(this.$t('rule-editor.rules.rule_exists', { name: newName }));
        return;
      }

      if (newName.split("/").length > 2) {
        Message.error(this.$t('rule-editor.rules.only_one_folder'));
        return;
      }

      if (!newName || newName.trim() === '' || newName.split("/").some(segment => segment.trim() === "")) {
        Message.error(this.$t('rule-editor.rules.no_empty_folder_or_rule'));
        return;
      }

      if (rule.id !== this.defaultRule.id) {
        try {
          const msg =
            'Would you like to rename the rules and all nodes referencing it in the graph or only rename the rule?';
          await MessageBox.confirm(msg, 'Rule rename', {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Rename rule and nodes',
            cancelButtonText: 'Rename rule'
          });
          await this.renameRuleAndReferences(rule.name, newName);
        } catch (action) {
          if (action === 'cancel') {
            await this.renameOnlyTheRule(rule, newName);
          }
        }
      } else {
        Message.error(
          `It is not possible to rename the '${this.defaultRule.name}' node`
        );
      }
    },
    clearUndoStack(graph) {
      if ('undoStack' in graph) {
        // eslint-disable-next-line no-param-reassign
        delete graph.undoStack;
      }
      if (graph.children) {
        graph.children.forEach((child) => this.clearUndoStack(child));
      }
    },
    async renameRuleAndReferences(oldName, newName) {
      const productId = this.product.id;
      const rules = this.product.rules.map((rule) => {
        if (rule.graph) this.clearUndoStack(rule.graph);
        return rule;
      });
      await this.renameRule({ rules, oldName, newName });
      await this.refreshProduct({ productId });
      Message.info('The rule and its associated nodes have been renamed');
      this.$emit('change-made');
      this.$emit('update');
    },
    // eslint-disable-next-line
    async renameOnlyTheRule(rule, newName) {
      const productId = this.product.id;
      if (!rule.graph) {
        // eslint-disable-next-line no-param-reassign
        rule.graph = getDefaultGraph(rule.name);
      }
      const copy = { ...rule, name: newName };
      if (copy.graph) this.clearUndoStack(copy.graph);
      await this.updateRule({ ruleId: rule.id, rule: copy });
      await this.refreshProduct({ productId });
      Message.info('The rule has been renamed');
      this.$emit('change-made');
      this.$emit('update');
    },
    async removeRule(rule) {
      try {
        if (rule.is_locked) {
          Message.error('The rule is locked');
          return;
        }
        await MessageBox.confirm(
          `Are you sure you wish to delete “${rule.name}”`
        );
        await this.deleteRule({ ruleId: rule.id });
        Message.info('The rule has been deleted');
        const rules = this.product.rules;
        if (this.$router.currentRoute.params.ruleId === rule.id) {
          const ruleId = rules.length ? rules[0].id : null;
          this.$router.replace({
            name: 'product-rules-edit',
            params: { productId: this.product.id, ruleId },
            query: this.$router.currentRoute.query
          });
        }
        this.$emit('change-made');
        this.$emit('update');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    checkMove(evt) {
      this.draggedRule = evt.draggedContext.element.id;
      const segments = evt.draggedContext.element.name.split('/');
      const name = `${evt.to.id !== 'root' ? evt.to.id + '/' : ''}${segments[segments.length - 1]}`;
      if (evt.from !== evt.to) {
        this.$emit('folder-dragover', evt.to.id)

        this.rootDrag = (evt.to.id === 'root');

        if (!this.isValidName(name)) {
          this.moveError = this.$t('rule-editor.rules.reserved_word', {
            name
          })
          return false;
        }

        if (!name || name.split("/").some(segment => segment.trim() === '')) {
          this.moveError = this.$t('rule-editor.rules.no_empty_folder_or_rule');
          return false;
        }

        for (let i = 0; i < this.product.rules.length; i += 1) {
          if (this.product.rules[i].name === name.trim()) {
            this.moveError = this.$t('rule-editor.rules.rule_exists', { name });
            return false;
          }
        }
      }
      this.moveError = undefined
      return true;
    },
    async saveRulesOrder(evt) {
      this.draggedRule = undefined;
      this.rootDrag = false;

      const segments = evt.item._underlying_vm_.name.split('/');
      const newName = `${evt.to.id !== 'root' ? evt.to.id + '/' : ''}${segments[segments.length - 1]}`;

      if (this.moveError) {
        Message.error(this.moveError);
        this.moveError = undefined;
        return false;
      }

      if (evt.from !== evt.to) {
        if (evt.item._underlying_vm_.is_locked) {
          Message.error('The rule is locked');
          return false;
        }
        await this.renameRuleAndReferences(
          evt.item._underlying_vm_.name,
          newName
        );
        return true;
      }

      const { newIndex, oldIndex } = evt;
      /* eslint-disable no-param-reassign */
      const rules = this.getRulesSorted(
        this.product.rules.map((r) => {
          if (r.position === oldIndex) r.position = newIndex;
          else if (r.position === newIndex && r.position > oldIndex)
            r.position -= 1;
          else if (r.position === newIndex && r.position < oldIndex)
            r.position += 1;
          else if (
            oldIndex < newIndex &&
            r.position < newIndex &&
            r.position > oldIndex
          )
            r.position -= 1;
          else if (
            oldIndex > newIndex &&
            r.position > newIndex &&
            r.position < oldIndex
          )
            r.position += 1;
          return r;
        })
      ).map((r) => r.id);

      try {
        await api.updateRulesOrder(this.product.id, rules, this.tenant);
        Message.success('The order of the rules has been saved');
        this.$emit('change-made');
        this.$emit('update');
      } catch (err) {
        Message.error('Could not change the order of the rules');
      }

    },
    isMatched(rule) {
      return this.warnings === null
        ? true
        : this.warnings &&
        this.warnings[rule.name] &&
        !this.warnings[rule.name].find((w) => w.code === 'UnusedRule');
    },
    isEmpty(rule) {
      return (
        this.warnings &&
        this.warnings[rule.name] &&
        this.warnings[rule.name].find(
          (w) => w.id === 0 && w.code === 'EmptyRule'
        )
      );
    },
    hasCycle(rule) {
      // TODO Use rule.id
      return this.cycleRules && this.cycleRules.some((r) => r === rule.name);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

$error-color-dark: #721c24;

h3 {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  margin-bottom: 0;
}

.warningsSwitch {
  margin-bottom: 1rem;
}

.rules {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    position: relative;

    .grip {
      color: $color-gray;
    }

    a {
      flex: 1;

      &:hover {
        color: $color-axa-blue;
      }
    }

    .active {
      font-weight: bold;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-axa-blue;
        opacity: 0.05;
        pointer-events: none;
      }
    }
  }

  .actions {
    opacity: 0;
  }

  .rule {
    background: white;

    .d-flex {
      align-items: center;

      a {
        display: flex;
        align-items: center;
      }
    }

    .el-icon-warning {
      color: orange;
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .rule:hover {
    a span {
      color: $color-axa-blue;
    }

    .actions {
      opacity: 1;

      button:hover {
        color: $color-axa-blue;
      }
    }
  }
}

button,
label[for='file'] {
  cursor: pointer;
  background: white;
  border: 0;
  color: gray;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.move-up {
  margin-top: -10px;
}

label[for='file'] {
  font-weight: 600;
  padding: 12px 20px;
  margin: 0;
}

input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.flipY {
  transform: scaleY(-1);
}

.inline {
  display: inline-block;
}

.rules_container {
  overflow: visible;
}

.sidebar-link-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  >a {
    flex: 1;
  }
}

.warnings-count {
  text-align: center;
  font-weight: bold;
  color: #fff;
  background: #ff7979;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  margin-right: 5px;
}
</style>
